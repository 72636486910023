import React from 'react';
import MapLocation from '../components/MapLocation';

const Venue = () => {
  return (
    <div className="venue">
      <h1>Wedding Venue</h1>
      <MapLocation />
    </div>
  );
}

export default Venue;
