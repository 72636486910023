import React from 'react';

const ViewCard = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', padding: '20px', boxSizing: 'border-box' }}>
      <img
        src="/Users/ahmadhali/wedding-invitation/public/Ahmed&Amani Wedding Invitation.png" 
        alt="Invitation Card"
        style={{ maxWidth: '100%', maxHeight: '100%' }}
      />
    </div>
  );
};

export default ViewCard;
