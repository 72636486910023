import React from 'react';
import { useNavigate } from 'react-router-dom';
import CountDownTimer from '../components/CountDownTimer';

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="home">
      <h1>Ahmed & Amani</h1>
      <h2>إِنْ شَاءَ ٱللَّٰهُ</h2>
      <p>ON 19th OCTOBER 2024, AT MANHATTAN BUSINESS HOTEL BANQUET HALL</p>
      <CountDownTimer />
      <div className="buttons">
        <button onClick={() => navigate('/venue')}>See the Venue</button>
        <a href="/images/Ahmed&Amani Wedding Invitation1.png" target="_blank" rel="noopener noreferrer">
          <button>View Card</button>
        </a>
      </div>
    </div>
  );
};

export default Home;