import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Venue from './pages/Venue';
import ViewCard from './components/ViewCard';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/venue" element={<Venue />} />
        <Route path="/view-card" element={<ViewCard />} />
      </Routes>
    </Router>
  );
};

export default App;
