import React from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import '../CountDownTimer.css';

// Component to display individual countdown blocks
const CountdownBlock = ({ value, label }) => {
  return (
    <div className="countdown-block">
      <div className="countdown-value">{zeroPad(value)}</div>
      <div className="countdown-label">{label}</div>
    </div>
  );
};

// Countdown timer component
const CountDownTimer = () => {
  const weddingDate = new Date(Date.UTC(2024, 9, 19, 20, 30, 0));
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="countdown-container">
        <CountdownBlock value={days} label="DAYS" />
        <CountdownBlock value={hours} label="HOURS" />
        <CountdownBlock value={minutes} label="MINUTES" />
        <CountdownBlock value={seconds} label="SECONDS" />
      </div>
    );
  };

  return (
    <div className="countdown-wrapper">
      <Countdown date={weddingDate} renderer={renderer} />
    </div>
  );
};

export default CountDownTimer;
